<template>
  <div
    :style="{
      'background-image': `url(${pictureUrl})`,
      'background-repeat': 'no-repeat',
      'background-position': 'center',
      'background-size': 'cover',
    }"
  >
    <div id="main-page">
      <div id="header"><img src="@/assets/images/header2.jpg" /></div>
      <div id="content">
        <div class="col-12 col-lg-6">
          <div class="timeContainer">
            <div class="col-12 text-center text-lg-right">
              <h5 class="mb-2">{{ dateToday() }}</h5>
            </div>
            <div class="col-12 text-center text-lg-right">
              <div
                class="row justify-content-center justify-content-lg-end align-items-center"
              >
                <div class="col-auto pl-0 pl-lg-3 pr-1">Next Live Draw :</div>
                <div
                  class="col-auto pr-0 pr-lg-3 pl-1"
                  style="display: flex; justify-content: center"
                >
                  <div class="position-relative">
                    <div
                      :data-time="seconds"
                      id="clock-lottery"
                      class="clock m-0 text-center"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-12 text-center text-lg-right">
                                <div class="livedraw-time">
                                    <span class="text-uppercase">Live Draw Time »</span>
                                    <span class="font-weight-bold">Close</span>
                                    <span> : <i>19:40</i></span>
                                    <span>&nbsp;,&nbsp;</span>
                                    <span class="font-weight-bold">Open</span>
                                    <span> : <i>19:55</i></span>
                                </div>
                            </div> -->
          </div>
        </div>
        <div data-v-11dd4c9b="" class="today-result">
          <div data-v-11dd4c9b="" style="text-align: center">
            <h3 data-v-11dd4c9b="">
              Draw Date
              {{
                this.datetime
                  ? formatDate(this.datetime)
                  : formatDate(this.first.datetime)
              }}
            </h3>
          </div>
          <div data-v-11dd4c9b="" style="text-align: center; margin-top: 10px">
            <h1 data-v-11dd4c9b="">
              <span data-v-11dd4c9b=""
                ><span
                  style="width: 50px; display: inline-block; text-align: center"
                  id="dice6"
                  >{{
                    this.first && this.first.six ? this.first.six.charAt(0) : ""
                  }}</span
                ><span
                  style="width: 50px; display: inline-block; text-align: center"
                  id="dice5"
                  >{{
                    this.first && this.first.six ? this.first.six.charAt(1) : ""
                  }}</span
                ><span
                  style="width: 50px; display: inline-block; text-align: center"
                  id="dice4"
                  >{{
                    this.first && this.first.six ? this.first.six.charAt(2) : ""
                  }}</span
                ><span
                  style="width: 50px; display: inline-block; text-align: center"
                  id="dice3"
                  >{{
                    this.first && this.first.six ? this.first.six.charAt(3) : ""
                  }}</span
                ><span
                  style="width: 50px; display: inline-block; text-align: center"
                  id="dice2"
                  >{{
                    this.first && this.first.six ? this.first.six.charAt(4) : ""
                  }}</span
                ><span
                  style="width: 50px; display: inline-block; text-align: center"
                  id="dice1"
                  >{{
                    this.first && this.first.six ? this.first.six.charAt(5) : ""
                  }}</span
                >
              </span>
            </h1>
          </div>
          <!-- <div data-v-11dd4c9b="" style="text-align: center; margin-top: 10px;">
                                <h3 data-v-11dd4c9b="">ອອກຜົນລາງວັນ 15:45</h3>
                            </div>
                            <table data-v-11dd4c9b="">
                                <tr data-v-11dd4c9b="">
                                    <td data-v-11dd4c9b="">The Five Digits Prize</td>
                                    <td data-v-11dd4c9b="">{{this.first.five}}</td>
                                </tr>
                                <tr data-v-11dd4c9b="">
                                    <td data-v-11dd4c9b="">The Four Digits Prize</td>
                                    <td data-v-11dd4c9b="">{{this.first.four}}</td>
                                </tr>
                                <tr data-v-11dd4c9b="">
                                    <td data-v-11dd4c9b="">The Three Digits Prize</td>
                                    <td data-v-11dd4c9b="">{{this.first.three}}</td>
                                </tr>
                                <tr data-v-11dd4c9b="">
                                    <td data-v-11dd4c9b="">The Two Digits Prize</td>
                                    <td data-v-11dd4c9b="">{{this.first.twotop}}</td>
                                </tr>
                                <tr data-v-11dd4c9b="">
                                    <td data-v-11dd4c9b="">The Two Digits Prize</td>
                                    <td data-v-11dd4c9b="">{{this.first.twobottom}}</td>
                                </tr>
                            </table> -->
        </div>
        <div data-v-c4a0d7d0="" class="history-result">
          <table data-v-c4a0d7d0="">
            <thead data-v-c4a0d7d0="">
              <tr data-v-c4a0d7d0="">
                <th data-v-c4a0d7d0="" colspan="5">Lottery Results</th>
              </tr>
            </thead>
            <tbody data-v-c4a0d7d0="">
              <tr data-v-c4a0d7d0="">
                <td data-v-c4a0d7d0="">
                  <strong data-v-c4a0d7d0="">Draw Date</strong>
                </td>
                <td data-v-c4a0d7d0="" width="58%">
                  <strong data-v-c4a0d7d0="">The Six Digits Prize</strong>
                </td>
                <!-- <td data-v-c4a0d7d0="" width="18%"><strong data-v-c4a0d7d0="">The Three Digits Prize</strong>
                                        </td>
                                        <td data-v-c4a0d7d0="" width="18%"><strong data-v-c4a0d7d0="">The Two Digits Prize
                                                </strong></td>
                                        <td data-v-c4a0d7d0="" width="18%"><strong data-v-c4a0d7d0="">The Two Digits Prize</strong></td> -->
              </tr>
              <tr v-for="(item, index) in data">
                <td data-v-c4a0d7d0="">{{ formatDate(item.datetime) }}</td>
                <td data-v-c4a0d7d0="">{{ item.six }}</td>
                <!-- <td data-v-c4a0d7d0="">{{item.three}}</td>
                                        <td data-v-c4a0d7d0="">{{item.twotop}}</td>
                                        <td data-v-c4a0d7d0="">{{item.twobottom}}</td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <div id="r-content">
                        <div class="box-banner" v-for="(item,index) in banner"><img :src="item.image" class="banner"></div>
                    </div> -->
        <div class="clearfix"></div>
      </div>
      <div id="footer">Copyright {{ getFullYear() }} © Hongkong Visa</div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import axios from '@/axios.ts'

export default {
  name: 'Home',
  data () {
    return {
      AniDiceRandom: false,
      once: false,
      dic: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0
      },
      pictureUrl: '',
      first: '',
      data: [],
      banner: [],
      datetime: '',
      seconds: 0
    }
  },
  created () {
    this.getBackGround()
    this.getBanners()

    setInterval(() => {
      this.getLotto()
    }, 1000)
  },
  methods: {
    async getTimeServer () {
      return moment()
        .tz('Asia/Bangkok')
        .toISOString()
    },
    AniDice (dic) {
      this.dic[dic] = setInterval(this.rolldice, 100, dic)
    },
    rolldice (dic) {
      var ranNum = Math.floor(1 + Math.random() * 6)
      document.getElementById('dice' + dic).innerHTML = ranNum
    },
    stopDice (dic) {
      clearInterval(this.dic[dic])
      if (dic == 1) {
        document.getElementById('dice' + dic).innerHTML = this.first.six.charAt(
          5
        )
      } else if (dic == 2) {
        document.getElementById('dice' + dic).innerHTML = this.first.six.charAt(
          4
        )
      } else if (dic == 3) {
        document.getElementById('dice' + dic).innerHTML = this.first.six.charAt(
          3
        )
      } else if (dic == 4) {
        document.getElementById('dice' + dic).innerHTML = this.first.six.charAt(
          2
        )
      } else if (dic == 5) {
        document.getElementById('dice' + dic).innerHTML = this.first.six.charAt(
          1
        )
      } else if (dic == 6) {
        document.getElementById('dice' + dic).innerHTML = this.first.six.charAt(
          0
        )
      }
    },

    async getBackGround () {
      const background = {
        image:
          'https://firebasestorage.googleapis.com/v0/b/lotto-landingpage.appspot.com/o/images%2Fbackground.jpg?alt=media&token=6af31ef7-2e0c-47e2-a9a5-6dc3cc644f22'
      }
      if (background) {
        this.pictureUrl = background.image
      }
    },
    async getBanners () {
      this.banner = []
    },
    getFullYear () {
      return moment()
        .tz('Asia/Bangkok')
        .format('YYYY')
    },
    formatDate (date) {
      return moment(date)
        .tz('Asia/Bangkok')
        .format('DD MMMM YYYY')
    },
    dateToday () {
      return moment()
        .tz('Asia/Bangkok')
        .format('dddd, DD MMMM YYYY')
    },
    async getLotto () {
      await axios.get('/list-v2/1').then((response) => {
        this.data = []
        this.first = null
        const data = response.data
        for (let index = 0; index < data.length; index++) {
          const element = data[index]
          if (!this.first) {
            this.first = element
            if (this.AniDiceRandom) {
              continue
            }

          }
          if (this.once) {
            this.data.push(element)
          }

        }
        this.getLastData()
      })
    },
    async getLast () {
      await axios.get('/last-v2/1').then((response) => {
        this.first = response.data
      })
    },
    async getLastData () {
      const timezone = await this.getTimeServer()
      await axios.get('/last-v2/1').then((response) => {
        const datas = [response.data]
        if (datas) {
          for (let index = 0; index < datas.length; index++) {
            const data = datas[index]

            if (
              data &&
              data.datetime == moment(timezone).format('YYYY-MM-DD HH:mm')
            ) {
              // if (this.first.six !== data.six) {
              this.first = data
              // this.data.unshift(data)
              setTimeout(() => {
                this.stopDice(6)
                this.AniDiceRandom = false
              }, Number(6 + '000') + 1000)
              setTimeout(() => {
                this.stopDice(5)
              }, Number(5 + '000') + 1000)
              setTimeout(() => {
                this.stopDice(4)
              }, Number(4 + '000') + 1000)
              setTimeout(() => {
                this.stopDice(3)
              }, Number(3 + '000') + 1000)
              setTimeout(() => {
                this.stopDice(2)
              }, Number(2 + '000') + 1000)
              setTimeout(() => {
                this.stopDice(1)
              }, Number(1 + '000') + 1000)
              // }
            } else {
              const t1 = moment().tz('Asia/Bangkok')
              const t2 = moment(data.datetime).tz('Asia/Bangkok')
              const diff = t2.diff(t1, 'seconds')

              if (diff < 0) {
                const t3 = moment(data.datetime).tz('Asia/Bangkok').add(1, 'day')
                const diff2 = t3.diff(t1, 'seconds')

                $('#clock-lottery').FlipClock(diff2, {
                  countdown: true
                })
              } else {
                $('#clock-lottery').FlipClock(diff, {
                  countdown: true
                })
              }

              if (diff >= 0 && diff <= 1800 && this.AniDiceRandom === false) {
                this.AniDiceRandom = true
                this.AniDice(1)
                this.AniDice(2)
                this.AniDice(3)
                this.AniDice(4)
                this.AniDice(5)
                this.AniDice(6)
                this.datetime = data.datetime
              }
            }
          }
          this.once = true
        }
      })
    }
  }
}
</script>
<style scoped>
.timeContainer {
  display: grid;
  justify-content: end;
}

@media screen and (max-width: 600px) {
  .timeContainer {
    display: inherit;
  }
}

#main-page {
  margin: 0 auto;
  background-color: #fff;
  padding-bottom: 0;
}
#main-page #content {
  padding: 0 5px;
  margin: 0;
}
body {
  margin: 0px;
  height: 100%;
}
</style>
